import { Box } from '@mui/material'
import styled from 'styled-components'

export const ContactUsFormBox = styled(Box)`
  margin: 0;
  border-radius: 15px;
  text-align: center;
  .card {
    .card-header {
      width: 70%;
      margin: auto;
      text-align: center;
    }
  }
`
