export const listPayload = {
  page: 1,
  limit: 10,
  sort: '',
  keywords: {
    text_search: '',
  },
  output: 'list',
}
export const paginationsPayload = {
  page: 1,
  limit: 10,
  sort: '',
  keywords: {
    text_search: '',
  },
  output: 'pagination',
}
