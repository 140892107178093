import {
  SET_HOME_SLIDE,
  SET_HOME_SERVICE,
  SET_HOME_CLIENT,
  SET_HOME_PRODUCT,
  SET_HOME_NEWS,
  SET_BUSINESS_UNIT,
  SET_ABOUT_US_SLIDE,
  SET_BUSINESS_UNIT_SLIDE,
  SET_CAREER_SLIDE,
} from './actions'

const contentContent = {
  homeSlide: {},
  aboutUsSlide: {},
  businessUnitSlide: {},
  careerSlide: {},
  service: [],
  client: [],
  product: [],
  news: [],
  businessUnit: [],
}
export default function ContentReducer(state = contentContent, action) {
  switch (action.type) {
    case SET_HOME_SLIDE:
      return {
        ...state,
        homeSlide: action.payload,
      }
    case SET_HOME_SERVICE:
      return {
        ...state,
        service: action.payload,
      }
    case SET_HOME_CLIENT:
      return {
        ...state,
        client: action.payload,
      }
    case SET_HOME_PRODUCT:
      return {
        ...state,
        product: action.payload,
      }
    case SET_HOME_NEWS:
      return {
        ...state,
        news: action.payload,
      }
    case SET_BUSINESS_UNIT:
      return {
        ...state,
        businessUnit: action.payload,
      }
    case SET_ABOUT_US_SLIDE:
      return {
        ...state,
        aboutUsSlide: action.payload,
      }
    case SET_BUSINESS_UNIT_SLIDE:
      return {
        ...state,
        businessUnitSlide: action.payload,
      }
    case SET_CAREER_SLIDE:
      return {
        ...state,
        careerSlide: action.payload,
      }
    default:
      return {
        ...state,
      }
  }
}
