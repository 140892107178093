import { Button, Container, Grid, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { FooterBox } from '../components/styles/styledFooter'
import { footerData } from '../constant/default-data/footer'
import InstagramIcon from '@mui/icons-material/Instagram'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import ReactHtmlParser from 'react-html-parser'
import { useSelector } from 'react-redux'
import { allowedText, scrollToSection } from '../utility/Helpers'
import { useTranslation } from 'react-i18next'
import SGSCertificate from '../assets/certification/SGS_ISO-IEC 27001_TCL.png'

export default function AppFooter() {
  const { t } = useTranslation()
  const { logo, company } = footerData[0]
  const service = useSelector(({ content }) => content.service)
  const products = useSelector(({ content }) => content.product)
  const business = useSelector(({ content }) => content.businessUnit)
  const navigate = useNavigate()

  const redirectLink = (URIRedirect) => {
    window.location.href = URIRedirect
    return null
  }

  return (
    <FooterBox>
      <Container className="top" disableGutters={true}>
        <Grid
          className="footer_menu"
          container
          direction={{ xs: 'column-reverse', md: 'row' }}
          spacing={2}
          justifyContent={{ xs: 'center', md: 'space-evenly' }}
        >
          <Grid sx={{ order: 2 }} item xs={11}>
            <Box className="logo" to={'/'}>
              <img loading="lazy" src={logo} alt="WideTechLogo" />
            </Box>
          </Grid>
          <Grid sx={{ order: 3 }} item xs={12} md={3} className=" mobile-order">
            <Typography variant="subtitle1">{company.name}</Typography>
            <Typography variant="subtitle2">{ReactHtmlParser(company.address)}</Typography>
            <a href={`tel:${company.phonep}`}>
              <Typography variant="subtitle1">{company.phone}</Typography>
            </a>
            <Box className="social_media">
              <a
                href="https://www.instagram.com/widetechnologies.id/"
                rel="noreferrer"
                target="_blank"
              >
                <InstagramIcon />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.linkedin.com/company/widetechnologies/"
              >
                <LinkedInIcon />
              </a>
            </Box>
            <Button variant="contained" size="medium" onClick={() => navigate('/contact-us')}>
              {t('button.contact_us')}
            </Button>
          </Grid>
          <Grid
            sx={{ order: 4 }}
            className="menu_list"
            item
            container
            xs={12}
            md={8}
            spacing={2}
            justifyContent={{ xs: 'center', md: 'space-between' }}
          >
            <Grid item xs={12} md={3}>
              <Typography variant="subtitle1">{t('title.our_service')}</Typography>
              {service.length
                ? service.map(({ title }, i) => (
                    <Typography
                      variant="subtitle2"
                      key={`service-menu-${i}`}
                      component="a"
                      onClick={() => {
                        navigate('/')
                        setTimeout(() => {
                          scrollToSection('#service')
                        }, 1000)
                      }}
                    >
                      {title}
                    </Typography>
                  ))
                : null}
            </Grid>
            <Grid item xs={12} md={3}>
              <Typography variant="subtitle1">{t('title.our_products')}</Typography>
              {products.length
                ? products.map(({ title, uid }, i) => (
                    <Link
                      to={`/products/${allowedText(title, {
                        lower_case: true,
                        space_convert: '-',
                      })}`}
                      state={{ items: { uid: uid } }}
                      key={`products-menu-${i}`}
                    >
                      <Typography variant="subtitle2">{title}</Typography>
                    </Link>
                  ))
                : null}
            </Grid>
            <Grid item xs={12} md={3}>
              <Typography variant="subtitle1">{t('title.business_unit')}</Typography>
              {business.length
                ? business.map(({ business_name, business_url }, i) => (
                    <Typography
                      variant="subtitle2"
                      key={`unit-menu-${i}`}
                      component="a"
                      onClick={() => {
                        redirectLink(business_url)
                      }}
                    >
                      {business_name}
                    </Typography>
                  ))
                : null}
            </Grid>
            <Grid item xs={12} md={3}>
              <Typography variant="subtitle1">{t('title.certified_by')}</Typography>
              <a
                href={'https://www.sgsgroup.cz/en/vr/certified-client-directory'}
                target="_blank"
                rel="noreferrer"
              >
                <img
                  loading="lazy"
                  className="certificate-logo"
                  src={SGSCertificate}
                  alt={`certificate-logo-sgs-iso/iec-27001`}
                />
              </a>
            </Grid>
          </Grid>
        </Grid>
      </Container>
      <Container className="bottom" disableGutters={true} maxWidth="false">
        <Typography>
          <strong>{t('title.company_wide')}</strong>
        </Typography>
      </Container>
    </FooterBox>
  )
}
