import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Pagination, Autoplay } from 'swiper'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'
import { Link } from 'react-router-dom'
import { Card, CardContent, CardMedia, Typography } from '@mui/material'
import { PropTypes } from 'prop-types'
import { useTranslation } from 'react-i18next'

const Slider = ({ sliderList }) => {
  const { t } = useTranslation()
  return (
    <Swiper
      slidesPerView={2}
      spaceBetween={55}
      loop={true}
      pagination={{
        dynamicBullets: true,
      }}
      autoplay={{
        delay: 3000,
        disableOnInteraction: true,
      }}
      breakpoints={{
        0: {
          slidesPerView: 1,
        },
        600: {
          slidesPerView: 2,
        },
        900: {
          slidesPerView: 2,
        },
        1400: {
          slidesPerView: 3,
        },
      }}
      navigation={true}
      modules={[Pagination, Navigation, Autoplay]}
      className="mySwiper"
    >
      {sliderList.map((items, i) => (
        <SwiperSlide key={`news_${i}`}>
          <Link to={items.path} state={items ? { items } : null}>
            <Card className="card card-gradient-hover" sx={{ boxShadow: 4 }}>
              <CardMedia
                component="img"
                height="250"
                image={items.media_url ? items.media_url : items.logo}
                alt={`productImg_${i}`}
              />
              <CardContent>
                <Typography className="title" variant="h4" component="h4">
                  {items.title}
                </Typography>
                <Typography>{items.headline ? items.headline : items.headlines}</Typography>
                <Typography component="span">{t('navigator.read_more')}</Typography>
              </CardContent>
            </Card>
          </Link>
        </SwiperSlide>
      ))}
    </Swiper>
  )
}
Slider.propTypes = {
  sliderList: PropTypes.array,
}
export default Slider
