import { Box } from '@mui/material'
import styled from 'styled-components'
import { ThemeOptions } from '../../config/ThemeOptions'

const { palette, fonts } = ThemeOptions
export const HeaderMobileBox = styled(Box)`
  .burger {
    color: ${palette.primary.main};
    svg {
      width: ${fonts.xl};
      height: ${fonts.xl};
    }
  }
`
export const DrawerBox = styled(Box)`
  margin: 0px 20px;
  img {
    width: 60px;
    height: 60px;
  }
`
