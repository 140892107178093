import { useCallback } from 'react'
import ApiService from '../../services/ApiService'

const InteractorContactUsCreate = () => {
  const createMessage = useCallback((body, loading) => {
    ApiService.jsonRequest('/inbox/create', body, (response) => {
      loading(false)
    })
  }, [])
  return { createMessage }
}

export default InteractorContactUsCreate
