import { useCallback } from 'react'
import ApiService from '../../services/ApiService'

const InteractorNewsSearch = () => {
  const newsSearch = useCallback((body, callback, loading) => {
    const payload = {
      ...body,
    }
    ApiService.jsonRequest('/content/news/search', payload, (response) => {
      loading(false)
      if (response.code === 7200) {
        callback(response.data)
      } else {
        callback(null)
      }
    })
  }, [])

  return { newsSearch }
}

export default InteractorNewsSearch
