import i18n from './i18n'
import * as Yup from 'yup'

const { t } = i18n
export const FormSchema = Yup.object().shape({
  full_name: Yup.string()
    .trim()
    .matches(/\S/, t('message.error_empty'))
    .min(2, `${t('message.error_min_length')} 2`)
    .max(30, `${t('message.error_max_length')} 30`)
    .required(t('message.error_required')),
  email_address: Yup.string()
    .trim()
    .email(t('message.error_email'))
    .required(t('message.error_required')),
  company_name: Yup.string()
    .trim()
    .matches(/\S/, t('message.error_required'))
    .min(2, `${t('message.error_min_length')} 2`)
    .max(30, `${t('message.error_max_length')} 30`)
    .required(t('message.error_required')),
  phone_number: Yup.string()
    .trim()
    .matches(/^[0-9]+$/, `${t('message.error_digit')}`)
    .min(6, `${t('message.error_min_length')} 6`)
    .max(16, `${t('message.error_max_length')} 16`)
    .required(t('message.error_required')),
  message: Yup.string()
    .trim()
    .matches(/\S/, t('message.error_required'))
    .min(10, `${t('message.error_min_length')} 10`)
    .max(300, `${t('message.error_max_length')} 300`)
    .required(t('message.error_required')),
  captha: Yup.string().trim().required(),
})
export const SchemaJob = Yup.object().shape({
  full_name: Yup.string()
    .trim()
    .matches(/\S/, t('message.error_required'))
    .min(2, `${t('message.error_min_length')} 2`)
    .max(30, `${t('message.error_max_length')} 30`)
    .required(t('message.error_required')),
  email_address: Yup.string()
    .trim()
    .email(`${t('message.error_email')}`)
    .required(t('message.error_required')),
  current_company: Yup.string()
    .trim()
    .matches(/\S/, t('message.error_required'))
    .min(2, `${t('message.error_min_length')} 2`)
    .max(30, `${t('message.error_max_length')} 30`)
    .required(t('message.error_required')),
  phone_number: Yup.string()
    .trim()
    .matches(/^[0-9]+$/, 'Must be only digits')
    .min(6, `${t('message.error_min_length')} 10`)
    .max(16, `${t('message.error_max_length')} 16`)
    .required(t('message.error_required')),
  additional_information: Yup.string()
    .trim()
    .matches(/\S/, t('message.error_required'))
    .min(10, `${t('message.error_min_length')} 10`)
    .max(500, `${t('message.error_max_length')} 500`)
    .required(t('message.error_required')),
  captha: Yup.string().trim().required(),
  resume_cv_url: Yup.string().trim().required(),
})
