import { createTheme } from '@mui/material'
import '../components/styles/main.css'

const font = "'Montserrat', sans-serif;"

export const ThemeOptions = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#1890ff',
      light: '#2c77a32b',
      dark: '#2C77A3',
    },
    secondary: {
      main: '#ffab2d',
      dark: '#E9970D',
    },
    text: {
      primary: '#0f3657',
    },
    warning: {
      main: '#ffff00',
    },
    white: {
      main: '#fff',
      dark: '#f1f1f1',
    },
    gradient_blue: {
      main: 'linear-gradient(75.08deg, #2C77A3 4.62%, #3AB6FF 51.22%, #A4DDFF 95.52%)',
      inverted: 'linear-gradient(238deg, #2C77A3 4.62%, #3AB6FF 51.22%, #A4DDFF 95.52%)',
    },
    gradient_blue_yellow: {
      main: 'linear-gradient(76.45deg, #FFFF00 -46.68%, #3AB6FF 92.7%, #2C77A3 123.78%)',
      inverted: 'linear-gradient(238deg, #FFFF00 -46.68%, #3AB6FF 92.7%, #2C77A3 123.78%)',
    },
    gradient_red_blue: {
      main: 'linear-gradient(76.01deg, #3AB6FF -7.16%, #B1737A 75.28%, #FFB038 138.04%)',
      inverted: 'linear-gradient(238deg, #3AB6FF -7.16%, #B1737A 75.28%, #FFB038 138.04%)',
    },
    gradient_yellow_blue: {
      main: 'linear-gradient(49deg, rgba(255,239,97,1) 0%, rgba(255,171,45,1) 27%, rgba(58,182,255,1) 100%)',
    },
    soft_blue: {
      v1: 'rgba(8, 157, 244, 0.3)',
      v2: 'rgba(58, 182, 255, 0.20)',
      v3: 'rgba(0, 131, 208, 0.1)',
    },
    career: {
      v1: 'rgba(54, 161, 223, 1)',
      v2: 'rgba(26, 77, 122, 1)',
      v3: 'rgba(44, 119, 163, 1)',
    },
  },
  typography: {
    fontFamily: font,
    fontSize: 18,
    h1: {
      fontSize: 22,
      fontWeight: 600,
      '@media screen and (max-width: 600px)': {
        fontSize: '16px',
      },
    },
    h2: {
      fontSize: 26,
      fontWeight: 600,
    },
    h3: {
      fontSize: 38,
      fontWeight: 700,
      '@media screen and (max-width: 600px)': {
        fontSize: '26px',
      },
    },
    h4: {
      fontSize: 26,
      fontWeight: 700,
    },
    h5: {
      fontSize: 16,
    },
    body1: {
      '@media screen and (max-width: 600px)': {
        fontSize: '17px',
      },
    },
    subtitle1: {
      fontWeight: 600,
      color: '#0f3657',
      margin: '10px 0',
      textTransform: 'capitalize',
    },
    subtitle2: {
      marginBottom: 6,
      '@media screen and (max-width: 600px)': {
        fontSize: '16px',
      },
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: '#fff',
          boxShadow: '0px 5px 5px 0px rgba(0,0,0,0.10)',
          transition: 'ease-in-out 0.25s',
          height: 70,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          margin: '0 5px',
          textTransform: 'capitalize',
          padding: '8px 12px',
          '@media screen and (max-width: 600px)': {
            fontSize: '17px',
          },
          '&:hover': {
            backgroundColor: '#ffab2d',
            color: '#e0e6eb',
          },
          svg: {
            margin: '0 5px',
          },
        },
        text: {
          background: '#fff',
        },
        sizeSmall: {
          borderRadius: '5px',
        },
        sizeMedium: {
          borderRadius: '7px',
        },
        sizeLarge: {
          borderRadius: '8px',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: 16,
        },
      },
    },
    MuiModal: {
      defaultProps: {
        disableScrollLock: true,
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          minHeight: '200vh',
          backgroundColor: 'transparent',
        },
      },
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          subtitle2: 'p',
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          padding: '5px 0px',
          paddingLeft: 5,
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        root: {
          span: {
            fontWeight: '600',
            img: {
              width: '40%',
              height: '100%',
              maxHeight: '32px',
            },
          },
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: 42,
          color: '#0f3657',
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        flexContainer: {
          justifyContent: 'space-around',
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        option: {
          textAlign: 'start',
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginLeft: 0,
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          width: '100%',
          fontSize: 16,
          '& .MuiOutlinedInput-root': {
            borderRadius: '10px',
            fieldset: {
              borderColor: '#1890ff',
            },
            '&:hover fieldset': {
              borderColor: '#1890ff',
              boxShadow: '0px 2px 5px 0px #188fff57',
            },
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: 18,
          marginTop: 2,
          span: {
            color: 'red',
          },
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        title: {
          fontSize: 18,
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          '@media screen and (min-width: 1200px)': {
            maxWidth: '1300px',
          },
        },
      },
    },
  },
  fonts: {
    xs: '0.75rem',
    sm: '0.875rem',
    md: '1.5rem',
    lg: '1.625rem',
    xl: '2rem',
    xxl: '3.875rem',
  },
  boxShadow: {
    xs: '0px 5px 5px 0px rgba(0,0,0,0.10)',
    sm: '0px 5px 10px 0px rgba(0,0,0,0.15)',
  },
})
