import React from 'react'
import { PropTypes } from 'prop-types'
import { Grid } from '@mui/material'
import { TabsBox } from './styledTabComponent'
import { Container } from '@mui/system'
import ReactHtmlParser from 'react-html-parser'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation } from 'swiper'

export const TabComponent = ({ data }) => {
  return (
    <TabsBox>
      <Swiper
        slidesPerView={1}
        spaceBetween={55}
        navigation={true}
        modules={[Navigation]}
        className="mySwiper"
      >
        {data.map(({ feature_images, feature_description }, i) => (
          <SwiperSlide key={`news_${i}`}>
            <Container>
              <Grid container spacing={2} alignItems="center" justifyContent="space-evenly">
                <Grid item xs={11} md={4}>
                  <img loading="lazy" src={feature_images} alt="mockup" />
                </Grid>
                <Grid item xs={11} md={6}>
                  {ReactHtmlParser(feature_description)}
                </Grid>
              </Grid>
            </Container>
          </SwiperSlide>
        ))}
      </Swiper>
    </TabsBox>
  )
}

TabComponent.propTypes = {
  data: PropTypes.array,
}

export default TabComponent
