import React from 'react'
import loading from '../../assets/image/loading/loading.gif'
const Loading = () => {
  return (
    <div id="loading">
      <img
        loading="lazy"
        src={loading}
        alt="loading"
        style={{
          height: 125,
          width: 125,
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%,-50%)',
          borderRadius: '100px',
          objectFit: 'none',
        }}
      />
    </div>
  )
}

export default Loading
