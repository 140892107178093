import { Box } from '@mui/system'
import styled from 'styled-components'
import { ThemeOptions } from '../../config/ThemeOptions'

const { palette, breakpoints } = ThemeOptions
export const LeftSlidesBox = styled(Box)`
  background: ${palette.primary.light};
  padding-bottom: 4%;
  margin-bottom: 0px;
  .content {
    min-height: 50vh;
    @media screen and (max-width: ${`${breakpoints.values.md}px`}) {
      min-height: 40vh;
      margin-bottom: 24px;
    }
  }
  .card {
    border-radius: 15px;
    height: 100%;
    &:hover span {
      color: ${palette.secondary.main};
    }
  }
  .swiper {
    padding: 50px;
    @media screen and (max-width: ${`${breakpoints.values.md}px`}) {
      padding: 10px 50px;
      padding-bottom: 50px;
      width: 100vw;
    }
  }
  p,
  h4 {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  p {
    -webkit-line-clamp: 3;
    line-height: 1.3;
    margin: 20px 0px;
  }
  h4 {
    margin-bottom: 10px;
    -webkit-line-clamp: 1;
  }
  span {
    color: ${palette.primary.main};
    font-weight: 700;
  }
`
