import { Input } from '@mui/material'
import React, { useState } from 'react'
import { ButtonFaceBox, HoverButton, UploadBox } from './styledUploadButtonFile'
import AddIcon from '@mui/icons-material/Add'
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile'
import DeleteIcon from '@mui/icons-material/Delete'
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred'
import { Loading } from '../Main'
import { InteractorUploadMediaFile } from '../../interactors/Main'
import { validateFile } from '../../utility/Helpers'
import { useTranslation } from 'react-i18next'
import { PropTypes } from 'prop-types'

const UploadButtonFile = ({ error, cbFormik }) => {
  const { t } = useTranslation()
  const { upload } = InteractorUploadMediaFile()
  const [loading, setLoading] = useState(false)
  const [file, setFile] = useState('')
  const [message, setMessage] = useState([])
  const acceptType = '.doc,.docx,application/msword, .pdf, .gz, .rar, .tar, .zip, .7z'
  const handleUploadFile = (e) => {
    const files = e.target.files[0]
    let validatedFile = validateFile(files, 5, acceptType)
    setLoading(true)
    if (validatedFile.error) {
      setMessage(t(`message.${validatedFile.message}`))
      setLoading(false)
      setTimeout(() => {
        setMessage([])
      }, 2000)
    } else {
      upload(
        {
          category: 'ITEM',
          file: files,
          reference_uid: null,
        },
        (response) => {
          setFile(response)
          cbFormik(response.file_url)
        },
        setLoading,
      )
    }
  }
  return (
    <UploadBox
      sx={
        error
          ? {
              border: 'red 1px solid !important',
              '&:hover': { borderColor: '#1890ff !important' },
            }
          : null
      }
    >
      <Input
        name="file"
        id="file"
        required
        color="primary"
        type="file"
        onChange={handleUploadFile}
        accept={acceptType}
      />
      {loading ? (
        <Loading />
      ) : (
        <ButtonFaceBox>
          {!file ? (
            <>
              {message.length ? (
                <>
                  <ReportGmailerrorredIcon />
                  <div style={{ color: 'red' }}>{message}</div>
                </>
              ) : (
                <>
                  <AddIcon />
                  Upload / Drag File
                </>
              )}
            </>
          ) : (
            <>
              <InsertDriveFileIcon />
              {file?.file_original_name}
            </>
          )}
          <HoverButton className={file && 'active'} onClick={() => setFile(null)}>
            <DeleteIcon />
            Delete ?
          </HoverButton>
        </ButtonFaceBox>
      )}
    </UploadBox>
  )
}
UploadButtonFile.propTypes = {
  error: PropTypes.bool,
  cbFormik: PropTypes.func,
}
export default UploadButtonFile
