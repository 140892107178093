import { Box } from '@mui/system'
import styled from 'styled-components'
import { ThemeOptions } from '../../config/ThemeOptions'
import checklist from '../../assets/image/checklist.svg'

const { palette, fonts } = ThemeOptions
export const TabsBox = styled(Box)`
  text-align: start;
  .mySwiper {
    .swiper-slide {
      padding: 50px 0px;
      span {
        background: none !important;
      }
      &:nth-child(1) {
        background: ${palette.soft_blue.v1} !important;
      }
      &:nth-child(2) {
        background: ${palette.soft_blue.v2};
      }
      &:nth-child(3) {
        background: ${palette.soft_blue.v3};
      }
    }
  }
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: grid;
    gap: 1rem;
    li {
      display: grid;
      grid-template-columns: 0 1fr;
      gap: 0.3em 1.3em;
      align-items: start;
      font-size: 1.5rem;
      font-size: calc(${fonts.sm} + 4px);
      /* display: flex; */
      /* margin-bottom: 20px; */
      br {
        content: '';
      }
      &::before {
        content: '';
        min-width: 18px;
        min-height: 18px;
        margin-top: 6px;
        display: block;
        background: url(${checklist}) no-repeat;
        background-size: 100%;
      }
    }
  }
`
