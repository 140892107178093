import { Button, Grid, InputLabel, TextField, Typography } from '@mui/material'
import React, { createRef, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { GradientTitle, Loading, UploadButtonFile } from '../Main'
import { PropTypes } from 'prop-types'
import { ApplyFormBox } from './styledApplyForm'
import ReCAPTCHA from 'react-google-recaptcha'
import { useFormik } from 'formik'
import { InteractorJobApplicantCreate } from '../../interactors/Main'
import { SchemaJob } from '../../utility/FormValidationSchema'

const ApplyForm = ({ title, subtitle, size, textAlign, job_uid }) => {
  const { t } = useTranslation()
  const { applyJob } = InteractorJobApplicantCreate()
  let sizeForm = size ? size : 'small'
  const [loading, setLoading] = useState(false)
  const [send, setSend] = useState(false)
  const recaptchaRef = createRef()

  const recaptchaChange = (value) => {
    if (value) {
      formik.setFieldValue('captha', value)
    }
  }

  const handleValueComponent = (value) => {
    if (value) {
      formik.setFieldValue('resume_cv_url', value)
    }
    setLoading(false)
  }
  useEffect(() => {
    if (Object.keys(formik.errors).length) {
      let el = document.getElementsByClassName('Mui-error')[0]
      el?.parentElement?.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
  })
  const formik = useFormik({
    initialValues: {
      job_uid: job_uid,
      full_name: '',
      current_company: '',
      email_address: '',
      phone_number: '',
      additional_information: '',
      resume_cv_url: '',
      captha: '',
      status: 'APPLIED',
    },
    validationSchema: SchemaJob,
    onSubmit: (values) => {
      setLoading(true)
      applyJob(
        { ...values, phone_number: values.phone_number.toString() },
        (response) => setSend(response),
        setLoading,
      )
    },
  })
  const inputProps = {
    required: true,
    size: sizeForm,
    color: 'primary',
  }
  return (
    <ApplyFormBox component="form" autoComplete="off">
      {loading ? (
        <Loading />
      ) : (
        <>
          {send ? (
            <GradientTitle
              title={t('title.thank_you_job')}
              subtitle={t('subtitle.thank_you_job')}
            />
          ) : (
            <>
              <Grid container spacing={2} justifyContent="center">
                {title ? <GradientTitle title={title} textAlign={textAlign} /> : null}
                <Grid item xs={12}>
                  <Typography variant="h1" component="p" sx={{ textAlign: textAlign }}>
                    {subtitle}
                  </Typography>
                </Grid>
                <Grid container spacing={2} item xs={12} justifyContent="center">
                  <Grid item xs={12} md={4}>
                    <InputLabel required>{t('placeholder.fullname')}</InputLabel>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <TextField
                      name="full_name"
                      id="full_name"
                      placeholder={t('placeholder.fullname')}
                      error={formik.touched.full_name && formik.errors.full_name ? true : false}
                      onChange={formik.handleChange}
                      helperText={
                        formik.touched.full_name && formik.errors.full_name
                          ? formik.errors.full_name
                          : null
                      }
                      {...inputProps}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <InputLabel required>{t('placeholder.current_company')}</InputLabel>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <TextField
                      name="current_company"
                      id="current_company"
                      error={
                        formik.touched.current_company && formik.errors.current_company
                          ? true
                          : false
                      }
                      onChange={formik.handleChange}
                      placeholder={t('placeholder.current_company')}
                      helperText={
                        formik.touched.current_company && formik.errors.current_company
                          ? formik.errors.current_company
                          : null
                      }
                      {...inputProps}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <InputLabel required>{t('placeholder.email')}</InputLabel>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <TextField
                      name="email_address"
                      id="email_address"
                      error={
                        formik.touched.email_address && formik.errors.email_address ? true : false
                      }
                      onChange={formik.handleChange}
                      placeholder={t('placeholder.email')}
                      helperText={
                        formik.touched.email_address && formik.errors.email_address
                          ? formik.errors.email_address
                          : null
                      }
                      {...inputProps}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <InputLabel required>{t('placeholder.phone')}</InputLabel>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <TextField
                      name="phone_number"
                      id="phone_number"
                      error={
                        formik.touched.phone_number && formik.errors.phone_number ? true : false
                      }
                      onChange={formik.handleChange}
                      placeholder={t('placeholder.phone')}
                      helperText={
                        formik.touched.phone_number && formik.errors.phone_number
                          ? formik.errors.phone_number
                          : null
                      }
                      {...inputProps}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <InputLabel required>{t('placeholder.resume')}</InputLabel>
                  </Grid>
                  <Grid className="upload-file" container spacing={2} item xs={12} md={8}>
                    <Grid item>
                      <UploadButtonFile
                        error={
                          formik.touched.resume_cv_url && formik.errors.resume_cv_url ? true : false
                        }
                        cbFormik={handleValueComponent}
                      />
                    </Grid>
                    <Grid item>
                      <span id="my-helper-text" style={{ display: 'block', textAlign: 'start' }}>
                        {t('placeholder.file_type')}
                      </span>
                      <span id="my-helper-text" style={{ display: 'block', textAlign: 'start' }}>
                        {t('placeholder.max_size')}
                      </span>
                    </Grid>
                    {formik.touched.resume_cv_url && formik.errors.resume_cv_url ? (
                      <span
                        id="my-helper-text"
                        style={{
                          width: '100%',
                          color: 'red',
                          display: 'block',
                          textAlign: 'start',
                          marginTop: '4px',
                          paddingLeft: '16px',
                        }}
                      >
                        {t('validate.file')}
                      </span>
                    ) : null}
                  </Grid>
                  <Grid item xs={12}>
                    <InputLabel required>{t('placeholder.additional_information')}</InputLabel>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      name="additional_information"
                      id="additional_information"
                      error={
                        formik.touched.additional_information &&
                        formik.errors.additional_information
                          ? true
                          : false
                      }
                      onChange={formik.handleChange}
                      placeholder={t('placeholder.additional_information')}
                      helperText={
                        formik.touched.additional_information &&
                        formik.errors.additional_information
                          ? formik.errors.additional_information
                          : null
                      }
                      {...inputProps}
                      rows={3}
                      multiline
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <ReCAPTCHA
                      ref={recaptchaRef}
                      sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                      onChange={recaptchaChange}
                      className="ReCaptha"
                    />
                    {formik.touched.captha && formik.errors.captha ? (
                      <span
                        id="my-helper-text"
                        style={{ color: 'red', display: 'block', textAlign: 'center' }}
                      >
                        {t('validate.recaptcha')}
                      </span>
                    ) : null}
                  </Grid>
                </Grid>
              </Grid>

              <Button
                variant="contained"
                size={sizeForm === 'large' ? 'large' : 'medium'}
                sx={{ marginTop: 2 }}
                onClick={formik.handleSubmit}
              >
                {t('button.submit')}
              </Button>
            </>
          )}
        </>
      )}
    </ApplyFormBox>
  )
}
ApplyForm.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  job_uid: PropTypes.string,
  textAlign: PropTypes.string,
  size: PropTypes.oneOf(['large', 'medium', 'small']),
}
export default ApplyForm
