import { Box } from '@mui/system'
import React from 'react'
import Header from '../containers/header/Header'

const AppHeader = () => {
  return (
    <Box sx={{ margin: 0 }}>
      <Header />
    </Box>
  )
}

export default AppHeader
