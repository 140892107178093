import React from 'react'
import { GradientTitle, Slider } from '../Main'
import { Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { LeftSlidesBox } from './styledLeftSlides'
import { PropTypes } from 'prop-types'

const LeftSlides = ({ title, slideList, white }) => {
  const { t } = useTranslation()
  return (
    <LeftSlidesBox
      sx={
        white
          ? {
              background: '#fff !important',
            }
          : null
      }
    >
      <Grid
        className="content"
        container
        spacing={{ xs: 2, md: 4 }}
        alignItems="center"
        justifyContent="center"
      >
        <Grid item md={2}>
          <GradientTitle title={t(`title.${title}`)} gradient="blue" />
        </Grid>
        <Grid item md={8}>
          <Slider sliderList={slideList} />
        </Grid>
      </Grid>
    </LeftSlidesBox>
  )
}

LeftSlides.propTypes = {
  title: PropTypes.string,
  slideList: PropTypes.array,
  white: PropTypes.bool,
}

export default LeftSlides
