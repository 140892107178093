import { useCallback } from 'react'
import ApiService from '../../services/ApiService'

const InteractorJobApplicantCreate = () => {
  const applyJob = useCallback((body, callback, loading) => {
    ApiService.jsonRequest('/job/applicant/create', body, (response) => {
      loading(false)
      if (response.code === 7201) {
        callback(true)
      } else {
        callback(false)
      }
    })
  }, [])
  return { applyJob }
}

export default InteractorJobApplicantCreate
