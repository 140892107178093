import instagram from '../../assets/media/instagram.svg'
import linkedin from '../../assets/media/linkedin.svg'
import logo from '../../assets/image/wide-logo.svg'

export const footerData = [
  {
    logo: logo,
    company: {
      name: 'PT Wide Technologies Indonesia',
      address: `International Financial Centre <br/> 
      Tower 2 - 15th Floor<br/>
       Jl. Jenderal Sudirman Kav. 22-23,<br/>
       Jakarta 12920, Indonesia.`,
      phone: '+62 21 8062 3840',
      media: [
        { name: 'instagram', src: instagram, path: 'wwww.instagram.com' },
        { name: 'linkedin', src: linkedin, path: 'wwww.linkedin.com' },
      ],
    },
    service: {
      title: 'Service',
      menu: [
        {
          name: 'System Integration',
          path: '#Service_1',
        },
        {
          name: 'System Performance Review',
          path: '#Service_2',
        },
        {
          name: 'Advance Customer Support',
          path: '#Service_3',
        },
      ],
    },
    products: {
      title: 'Products',
      menu: [
        {
          name: 'PrimeCash',
          path: '#products_1',
        },
        {
          name: 'PrimeToken',
          path: '#products_2',
        },
        {
          name: 'PrimeMobile',
          path: '#products_3',
        },
        {
          name: 'PrimeGateway',
          path: '#products_3',
        },
        {
          name: 'PrimeCash COB',
          path: '#products_3',
        },
        {
          name: 'PrimeCash Fast',
          path: '#products_3',
        },
      ],
    },
    business: {
      title: 'business',
      menu: [
        {
          name: 'Prima Vista Solusi',
          path: '#business_1',
        },
        {
          name: 'WIDE Malaysia',
          path: '#business_2',
        },
      ],
    },
  },
]
