import React from 'react'
import CloseIcon from '@mui/icons-material/Close'
import { Button } from '@mui/material'
import { PropTypes } from 'prop-types'

const EscapeButton = ({ state, setStateCB }) => {
  const style = {
    position: 'absolute',
    top: 16,
    right: {
      xs: 0,
      md: 5,
    },
    padding: 0,
    minWidth: 50,
    svg: {
      margin: 0,
    },
  }
  return (
    <Button sx={style} onClick={() => setStateCB(!state)}>
      <CloseIcon />
    </Button>
  )
}
EscapeButton.propTypes = {
  state: PropTypes.bool,
  setStateCB: PropTypes.func,
}
export default EscapeButton
