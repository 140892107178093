import moment from 'moment'
import { validateMimeTypex } from './MimeType'

export const prettyUrl = (value) => {
  value = value.replace(/[^a-zA-Z ]/g, '')
  return value
    .replace(/ /g, '-')
    .replace(/@/g, '')
    .replace(/\$/g, '')
    .replace(/!/g, '')
    .replace(/#/g, '')
    .toLowerCase()
}

export const findByArray = (array, key, value) => {
  return array.find((element) => {
    return element[key] === value
  })
}

export const scrollToSection = (id) => {
  const element = document.getElementById(id.replace(/[^a-zA-Z0-9 ]/g, ''))
  const offset = 80
  const elPosition = element.getBoundingClientRect().top
  const elOffset = elPosition + window.pageYOffset - offset
  window.scrollTo({ top: elOffset, behavior: 'smooth' })
}

export const allowedText = (value, options) => {
  let text = value
  let optionsText = {
    lower_case: options.lower_case ?? false,
    upper_case: options.upper_case ?? false,
    stricted_symbol: options.stricted_symbol ?? null,
    space_convert: options.space_convert ?? null,
  }

  if (optionsText.lower_case) {
    text = text.toLowerCase()
  }

  if (optionsText.upper_case) {
    text = text.toUpperCase()
  }

  if (optionsText.stricted_symbol) {
    let regex = ''
    let conditions = optionsText.stricted_symbol
    if (conditions !== true) {
      regex = `[^a-zA-Z0-9 ${conditions?.length && conditions}${
        optionsText.space_convert?.length && optionsText.space_convert
      }]`
    } else {
      regex = `[^a-zA-Z0-9 ${optionsText.space_convert?.length && optionsText.space_convert}]`
    }
    regex = new RegExp(regex)
    text = text.replace(regex, '')
  }

  if (optionsText.space_convert === '') {
    text = text.replace(/\s/g, '')
  } else if (optionsText.space_convert) {
    text = text.replace(/\s/g, optionsText.space_convert)
  }

  return text
}

export const createPathInArray = (headpath, array) => {
  array.forEach((element) => {
    element.path = `/${headpath}/${prettyUrl(element.title)}`
  })
  return array
}

export const formatDate = (value, format) => {
  if (value !== undefined) {
    const unix_timestamp = Date.parse(new Date(value).toString())
    return moment(unix_timestamp).format(format)
  }
}

export const validateFile = (files, maxSizeMB) => {
  let fileSize = files.size / 1024 / 1024
  let message = ''
  let error = false
  if (fileSize <= 0) {
    message = 'error_corrupted'
    error = true
  }
  if (fileSize > maxSizeMB) {
    message = 'error_exceed_size'
    error = true
  }
  if (!validateMimeTypex(files)) {
    message = 'error_malicious'
    error = true
  }

  return { message, error }
}
