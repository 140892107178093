import React from 'react'
import { PropTypes } from 'prop-types'
import { Button, Grid, Typography } from '@mui/material'
import arrow from '../../../assets/image/arrow.svg'
import { findByArray } from '../../../utility/Helpers'

const TabsJoblist = ({ joblist, modalState, modalCb, jobDescCb }) => {
  return (
    <>
      {joblist.slice(0, 5).map(({ job_title, location_name, department_name }, i) => (
        <Grid item xs={12} sm={6} md={4} className="roles" key={`roles-${i}`}>
          <Typography variant="h4" component="h4">
            {job_title}
          </Typography>
          <Typography variant="subtitle2"> {department_name} </Typography>
          <Typography variant="subtitle2"> {location_name} </Typography>
          <Button
            variant="outlined"
            onClick={() => {
              modalCb(!modalState)
              jobDescCb(findByArray(joblist, 'job_title', job_title))
            }}
          >
            <img loading="lazy" src={arrow} alt="arrow" />
          </Button>
        </Grid>
      ))}
    </>
  )
}
TabsJoblist.propTypes = {
  joblist: PropTypes.array,
  modalState: PropTypes.bool,
  modalCb: PropTypes.func,
  jobDescCb: PropTypes.func,
}
export default TabsJoblist
