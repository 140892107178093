import {
  SET_ABOUT_US_SLIDE,
  SET_BUSINESS_UNIT,
  SET_BUSINESS_UNIT_SLIDE,
  SET_CAREER_SLIDE,
  SET_HOME_CLIENT,
  SET_HOME_NEWS,
  SET_HOME_PRODUCT,
  SET_HOME_SERVICE,
  SET_HOME_SLIDE,
} from './actions'

export const SetHomeClient = (payload) => {
  return (dispatch) => {
    dispatch({ type: SET_HOME_CLIENT, payload: payload })
  }
}
export const SetHomeNews = (payload) => {
  return (dispatch) => {
    dispatch({ type: SET_HOME_NEWS, payload: payload })
  }
}
export const SetHomeProduct = (payload) => {
  return (dispatch) => {
    dispatch({ type: SET_HOME_PRODUCT, payload: payload })
  }
}
export const SetHomeService = (payload) => {
  return (dispatch) => {
    dispatch({ type: SET_HOME_SERVICE, payload: payload })
  }
}
export const SetHomeSlide = (payload) => {
  return (dispatch) => {
    dispatch({ type: SET_HOME_SLIDE, payload: payload })
  }
}
export const SetBusinessUnit = (payload) => {
  return (dispatch) => {
    dispatch({ type: SET_BUSINESS_UNIT, payload: payload })
  }
}
export const SetAboutUsSlide = (payload) => {
  return (dispatch) => {
    dispatch({ type: SET_ABOUT_US_SLIDE, payload: payload })
  }
}
export const SetBusinessUnitSlide = (payload) => {
  return (dispatch) => {
    dispatch({ type: SET_BUSINESS_UNIT_SLIDE, payload: payload })
  }
}
export const SetCareerSlide = (payload) => {
  return (dispatch) => {
    dispatch({ type: SET_CAREER_SLIDE, payload: payload })
  }
}
