import React from 'react'
import { PropTypes } from 'prop-types'
import { ThemeOptions } from '../../config/ThemeOptions'
import { Typography } from '@mui/material'
import { Box } from '@mui/system'

const GradientTitle = ({ title, subtitle, gradient, logo, textAlign }) => {
  const { palette } = ThemeOptions
  const type =
    gradient === 'blue' || !gradient
      ? `-webkit-${palette.gradient_blue.main}`
      : gradient === 'blue_yellow'
      ? `-webkit-${palette.gradient_blue_yellow.main}`
      : gradient === 'red_blue'
      ? `-webkit-${palette.gradient_red_blue.main}`
      : gradient === 'red_blue_inverted'
      ? `-webkit-${palette.gradient_red_blue.inverted}`
      : gradient === 'yellow_blue'
      ? `-webkit-${palette.gradient_yellow_blue.main}`
      : null

  const style = {
    display: 'inline-block',
    background: type,
    textTransform: 'capitalize',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    textAlign: 'center',
    marginBottom: subtitle ? 2 : 4,
    marginTop: 3,
    position: 'relative',
    '&::before': {
      content: '""',
      position: 'absolute',
      width: '30px',
      height: '30px',
      background: `url(${logo}) no-repeat`,
      backgroundSize: 'contain',
      left: '-40px',
      top: '7px',
    },
  }
  return (
    <Box
      sx={{
        margin: '0 auto',
        textAlign: `${textAlign ? textAlign : 'center'}`,
        overflow: 'visible',
        h3: {
          textAlign: `${textAlign ? textAlign : 'center'} !important`,
        },
      }}
    >
      <Typography variant="h3" component="h3" sx={style}>
        {title}
      </Typography>
      {subtitle ? <Typography variant="subtitle2">{subtitle}</Typography> : null}
    </Box>
  )
}

GradientTitle.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  textAlign: PropTypes.string,
  gradient: PropTypes.oneOf([
    'blue_yellow',
    'blue',
    'red_blue',
    'red_blue_inverted',
    'yellow_blue',
  ]),
  logo: PropTypes.string,
}

export default GradientTitle
