import { Button } from '@mui/material'
import { Box } from '@mui/system'
import styled from 'styled-components'

export const ButtonFaceBox = styled(Box)`
  margin: 0;
  position: absolute;
  width: 90%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
  display: -webkit-box;
  max-width: 200px;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  svg {
    color: #1890ff;
    margin: 10px auto;
    height: 50px;
    width: 50px;
    display: block;
  }
  &:has(.active) {
    z-index: 1;
  }
`

export const HoverButton = styled(Button)`
  display: none;
  opacity: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  flex-direction: column;
  transition: all ease-in-out 0.2s;
  box-shadow: none;
  &:hover {
    color: red;
    background: #fff;
    box-shadow: none;
  }
`

export const UploadBox = styled(Box)`
  margin: 0;
  margin-bottom: 10px;
  width: 150px;
  height: 150px;
  border: #1890ff 1px solid;
  border-radius: 10px;
  position: relative;

  .MuiInputBase {
    &-root {
      width: 100%;
      height: 100%;
    }
    &-input {
      width: 100%;
      height: 100%;
      opacity: 0;
      &:hover {
        cursor: pointer;
      }
    }
  }
  &:hover {
    box-shadow: 0px 2px 5px 0px #188fff57;
    ${HoverButton} {
      opacity: 1;
    }
  }
  .active {
    display: block;
  }
  &:has(#loading) {
    img {
      margin: 0;
    }
  }
`
