import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'
import { Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import { Box } from '@mui/system'
import FeatherIcon from 'feather-icons-react'
import { Link, useNavigate } from 'react-router-dom'
import { DrawerBox, HeaderMobileBox } from './styledHeaderMobile'
import logo from '../../assets/image/wide-logo.svg'
import { useTranslation } from 'react-i18next'
import { scrollToSection } from '../../utility/Helpers'

function HeaderMobile(props) {
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)
  const { menuList, scrollPosition, loc } = props
  const navigate = useNavigate()

  const drawerMenu = (
    <DrawerBox>
      <Link onClick={() => setOpen(!open)} to="/">
        <img loading="lazy" src={logo} alt="WideTechLogo" />
      </Link>
      <List>
        <ListItem disablePadding>
          <ListItemButton
            onClick={() => {
              navigate('/')
              setOpen(!open)
            }}
          >
            <ListItemIcon>
              <FeatherIcon icon="home" />
            </ListItemIcon>
            <ListItemText primary="Home" />
          </ListItemButton>
        </ListItem>
        {menuList.map((items, i) => {
          let { name, path, logo } = items
          const hrefProps =
            path.search('#') === -1
              ? {
                  onClick: () => {
                    navigate(path)
                    setOpen(!open)
                  },
                }
              : {
                  onClick: () => {
                    if (loc === '/') {
                      scrollToSection(path)
                    } else {
                      navigate('/')
                      setTimeout(() => {
                        scrollToSection(path)
                      }, 1000)
                    }
                    setOpen(!open)
                  },
                }
          return (
            <Fragment key={`menu_${i}`}>
              <ListItem disablePadding>
                <ListItemButton {...hrefProps}>
                  <ListItemIcon>
                    <FeatherIcon icon={logo} />
                  </ListItemIcon>
                  <ListItemText primary={t(`menu.${name}`)} />
                </ListItemButton>
              </ListItem>
            </Fragment>
          )
        })}
      </List>
    </DrawerBox>
  )

  return (
    <HeaderMobileBox sx={{ display: { xs: 'block !important', md: 'none !important' } }}>
      <Box
        className="burger"
        sx={scrollPosition === 0 ? { color: '#fff !important' } : null}
        onClick={() => setOpen(!open)}
      >
        <FeatherIcon icon="menu" />
      </Box>
      <Drawer
        className="drawer"
        open={open}
        onClose={() => setOpen(!open)}
        sx={{
          display: { xs: 'block', md: 'none' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: `60%` },
        }}
      >
        {drawerMenu}
      </Drawer>
    </HeaderMobileBox>
  )
}

HeaderMobile.propTypes = {
  menuList: PropTypes.array,
  scrollPosition: PropTypes.number,
  loc: PropTypes.string,
}

export default HeaderMobile
