import styled from 'styled-components'
import { ThemeOptions } from '../../config/ThemeOptions'

const { palette, breakpoints } = ThemeOptions
export const FooterBox = styled.footer`
  background: ${palette.primary.light};
  @media screen and (max-width: ${`${breakpoints.values.md}px`}) {
    padding-top: 10px;
  }
  .top {
    @media screen and (max-width: ${`${breakpoints.values.md}px`}) {
      padding: 0 40px;
    }
    @media screen and (max-width: ${`${breakpoints.values.md}px`}) {
      padding: 0 20px;
    }
    .footer_menu {
      margin-top: 0px;
      margin-bottom: 50px;
      button {
        margin-left: 0px;
      }
      .logo {
        max-width: 120px;
        margin: 10px 0px;
        @media screen and (max-width: ${`${breakpoints.values.md}px`}) {
          margin: 20px 0;
        }
      }
    }
    .social_media {
      margin: 15px 0px;
      svg {
        color: ${palette.primary.main};
        width: 1.5em;
        height: 1.5em;
        margin-right: 10px;
        &:hover {
          color: ${palette.secondary.main};
        }
      }
    }
    .menu_list {
      cursor: pointer;
      a {
        display: block;
      }
      @media screen and (max-width: ${`${breakpoints.values.md}px`}) {
        margin-top: 0px;
        &::after {
          content: '';
          display: block;
          width: 90%;
          margin: 30px 0px;
          height: 2px;
          background: ${palette.gradient_red_blue.inverted};
        }
      }
    }
  }
  .bottom {
    max-width: unset !important;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 8vh;
    color: ${palette.white.main};
    background: ${palette.gradient_blue.main};
    p {
      font-size: 14px;
    }
  }
  @media screen and (max-width: ${`${breakpoints.values.md}px`}) {
    .mobile-order {
      order: 1;
    }
  }
  .certificate-logo {
    width: 45%;
    height: auto;
  }
  @media screen and (max-width: ${`${breakpoints.values.md}px`}) {
    .certificate-logo {
      width: 20%;
      height: auto;
    }
  }
`
