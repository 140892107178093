import { Box } from '@mui/material'
import styled from 'styled-components'

export const ApplyFormBox = styled(Box)`
  margin: 0;
  border-radius: 15px;
  text-align: center;
  label {
    text-align: start;
  }
  #loading img {
    margin: 0;
  }
  @media screen and (max-width: 410px) {
    .upload-file {
      div:nth-child(1) {
        order: 2;
      }
    }
  }
`
