import { lazy } from 'react'

const Home = lazy(() => import('./home/Home'))
const AboutUs = lazy(() => import('../containers/about-us/AboutUs'))
const ProductPage = lazy(() => import('../containers/products/ProductPage'))
const ContactUsPage = lazy(() => import('../containers/contact/ContactUsPage'))
const BusinessUnitPage = lazy(() => import('../containers/business-unit/BusinessUnitPage'))
const CareerPage = lazy(() => import('../containers/career/CareerPage'))
const NewsPage = lazy(() => import('../containers/news/NewsPage'))

export { Home, AboutUs, ProductPage, ContactUsPage, BusinessUnitPage, CareerPage, NewsPage }
