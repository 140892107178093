import { Button, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { PropTypes } from 'prop-types'
import { findByArray } from '../../../utility/Helpers'
import { Loading } from '../../Main'

const TableJoblist = ({
  joblist,
  modalState,
  modalCb,
  jobDescCb,
  dataRenderedState,
  loadDataFunc,
}) => {
  const { t } = useTranslation()

  return (
    <>
      {!joblist ? (
        <Loading />
      ) : (
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>{t('title.table_title')}</TableCell>
              <TableCell>{t('title.table_location')}</TableCell>
              <TableCell>{t('title.table_departement')}</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {joblist.map(({ uid, department_name, location_name, job_title }, i) => (
              <TableRow
                key={`jobs-${i}`}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell>{job_title}</TableCell>
                <TableCell>{location_name}</TableCell>
                <TableCell>{department_name}</TableCell>
                <TableCell>
                  <Link
                    onClick={() => {
                      modalCb(!modalState)
                      jobDescCb(findByArray(joblist, 'job_title', job_title))
                    }}
                    to=""
                  >
                    {t('button.apply')}
                  </Link>
                </TableCell>
              </TableRow>
            ))}
            {!dataRenderedState && (
              <TableRow>
                <TableCell colSpan={4} sx={{ textAlign: 'center' }}>
                  <Button variant="outline" onClick={loadDataFunc}>
                    {t('button.load_more')}
                  </Button>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      )}
    </>
  )
}

TableJoblist.propTypes = {
  joblist: PropTypes.array,
  modalState: PropTypes.bool,
  dataRenderedState: PropTypes.bool,
  modalCb: PropTypes.func,
  jobDescCb: PropTypes.func,
  loadDataFunc: PropTypes.func,
}
export default TableJoblist
