export const menuList = [
  {
    name: 'product',
    path: '/#products',
    logo: 'box',
  },
  {
    name: 'service',
    path: '/#service',
    logo: 'award',
  },
  {
    name: 'about',
    path: '/about-us',
    logo: 'globe',
  },
  {
    name: 'career',
    path: '/career',
    logo: 'pen-tool',
  },
  {
    name: 'business',
    path: '/business-unit',
    logo: 'briefcase',
  },
  {
    name: 'contact',
    path: '/contact-us',
    logo: 'phone-call',
  },
]
