import Swal from 'sweetalert2'
import i18n from './i18n'

function i18nValue(key) {
  let value = i18n.t(key)
  if (value === undefined) {
    value = key
  }
  return value
}

const showAlert = (row) => {
  Swal.fire({
    position: 'center',
    icon: row.icon,
    html: row.message,
    showCloseButton: false,
    focusConfirm: true,
    confirmButtonText: `<span class="text-dark alert-link">${i18nValue('button.ok')}</span>`,
    confirmButtonColor: '#5F63F2',
    color: '#333e48',
    timer: row.timer,
  })

  // RELOAD or REDIRECT
  if (row?.reload) {
    setTimeout(() => {
      window.location.reload(false)
    }, row.timer)
  } else if (row?.redirect) {
    setTimeout(() => {
      window.location.href = row.redirect
    }, row.timer)
  }
}

const confirmationAlert = async (row) => {
  return Swal.fire({
    html: row.message,
    icon: row.icon,
    showCancelButton: true,
    confirmButtonColor: '#5F63F2',
    cancelButtonColor: '#d33',
    confirmButtonText: i18nValue('button.ok'),
    cancelButtonText: i18nValue('button.cancel'),
  }).then((result) => {
    return result.isConfirmed
  })
}

export { confirmationAlert, showAlert }
