import { KeyboardDoubleArrowRightOutlined } from '@mui/icons-material'
import { Grid, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { UnitDetailContainer } from './styledUnitDetail'
import mockUp from '../../assets/image/mockup (1).svg'
import { useSelector } from 'react-redux'
import { Loading } from '../Main'
import { allowedText } from '../../utility/Helpers'

const UnitDetail = () => {
  const { t } = useTranslation()
  const unit = useSelector(({ content }) => content.businessUnit)
  return (
    <UnitDetailContainer>
      {!unit.length ? (
        <Loading />
      ) : (
        <>
          {unit.map(
            (
              {
                business_name,
                business_logo,
                business_url,
                business_headlines,
                business_product_preview,
              },
              i,
            ) => (
              <Grid
                id={`#${allowedText(business_name, { lower_case: true, space_convert: '_' })}`}
                container
                className="unit"
                spacing={{ xs: 2, md: 4 }}
                item
                key={`unit-${i}`}
                alignItems="start"
              >
                <Grid className="unit-item" item xs={12} md={6}>
                  <img
                    loading="lazy"
                    className="unit-logo"
                    src={business_logo ? business_logo : mockUp}
                    alt={`unit-logo-${business_name}`}
                  />
                  <Box className="unit-headlines">
                    <Typography>{business_headlines}</Typography>
                    <a
                      className="unit-link"
                      target="_blank"
                      href={business_url ? business_url : '#'}
                      rel="noreferrer"
                    >
                      {t('navigator.learn_more')}
                      <KeyboardDoubleArrowRightOutlined />
                    </a>
                  </Box>
                </Grid>
                <Grid className="unit-item" item xs={12} md={6}>
                  <img
                    loading="lazy"
                    className="unit-preview"
                    src={business_product_preview ? business_product_preview : mockUp}
                    alt={`unit-preview-${business_name}`}
                  />
                </Grid>
              </Grid>
            ),
          )}
        </>
      )}
    </UnitDetailContainer>
  )
}

export default UnitDetail
