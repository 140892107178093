const AVI_KEY = ['52494646']
const BMP_KEY = ['424d']
const CSV_KEY = ['53657269']
const DOC_KEY = [
  'd0cf11e0a1b11ae1',
  '0d444f43',
  'cf11e0a1b11ae100',
  'dba52d00',
  'eca5c100',
  '504b34',
  '504b0304',
  '504b030414000600',
]
const EXCEL_KEY = [
  'd0cf11e0',
  'd0cf11e0a1b11ae1',
  '0908100000060500',
  'fdffffff10',
  'fdffffff1f',
  'fdffffff22',
  'fdffffff23',
  'fdffffff28',
  'fdffffff29',
  '504b0304',
  '504b030414000600',
]
const FLV_KEY = ['464c56']
const GIF_KEY = ['47494638']
const GZ_KEY = ['1f8b08']
const JPEG_KEY = ['ffd8ffe0', 'ffd8ffe1', 'ffd8ffe2', 'ffd8ffe3', 'ffd8ffe8']
const MKV_KEY = ['1a45dfa393428288']
const MOV_KEY = ['6d6f6f76', '66726565', '6d646174', '77696465', '706e6f74', '736b6970']
const MPG_KEY = ['000001ba', '000001b3']
const PDF_KEY = ['25504446']
const PNG_KEY = ['89504e470d0a1a0a', '89504e47']
const RAR_KEY = ['526172211a0700']
const THREEGP_KEY = ['0000001466747970', '0000002066747970']
const WMV_KEY = ['3026b2758e66cf11']
const ZIP_KEY = [
  '504b0304',
  '504b4c495445',
  '504b537058',
  '504b0506',
  '504b0708',
  '57696e5a6970',
  '504b030414000100',
]

const getBlob = (blob) => {
  return new Promise((resolve) => {
    const fileReader = new FileReader()
    fileReader.onloadend = (e) => {
      var arr = new Uint8Array(e.target.result).subarray(0, 4)
      var header = ''
      for (var i = 0; i < arr.length; i++) {
        header += arr[i].toString(16)
      }
      resolve(mimeType(header))
    }
    fileReader.readAsArrayBuffer(blob)
  })
}

function mimeType(headerString) {
  var type = []
  if (AVI_KEY.includes(headerString)) {
    type.push('video/avi')
  } else if (BMP_KEY.includes(headerString)) {
    type.push('image/bmp')
  } else if (CSV_KEY.includes(headerString)) {
    type.push('text/csv')
  } else if (DOC_KEY.includes(headerString) || EXCEL_KEY.includes(headerString)) {
    type.push(
      'application/doc',
      'application/ms-doc',
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/excel',
      'application/vnd.ms-excel',
      'application/x-excel',
      'application/x-msexcel',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    )
  } else if (FLV_KEY.includes(headerString)) {
    type.push('video/x-flv')
  } else if (GIF_KEY.includes(headerString)) {
    type.push('image/gif')
  } else if (GZ_KEY.includes(headerString)) {
    type.push('application/gzip')
  } else if (MKV_KEY.includes(headerString)) {
    type.push('video/x-matroska', 'application/octet-stream')
  } else if (MOV_KEY.includes(headerString)) {
    type.push('video/quicktime')
  } else if (MPG_KEY.includes(headerString)) {
    type.push('video/mpeg')
  } else if (PDF_KEY.includes(headerString)) {
    type.push('application/pdf')
  } else if (JPEG_KEY.includes(headerString)) {
    type.push('image/jpeg')
  } else if (PNG_KEY.includes(headerString)) {
    type.push('image/png')
  } else if (RAR_KEY.includes(headerString)) {
    type.push('application/vnd.rar')
  } else if (THREEGP_KEY.includes(headerString)) {
    type.push('video/3gpp', 'audio/3gpp')
  } else if (WMV_KEY.includes(headerString)) {
    type.push('video/x-ms-wmv')
  } else if (ZIP_KEY.includes(headerString)) {
    type.push('application/zip')
  } else {
    type.push('unknown')
  }
  return type
}

const validateMIMEType = async (file) => {
  const blobType = file.type
  const SameMimeResult = getBlob(file).then((val) => {
    return val.includes(blobType)
  })
  return await SameMimeResult
}

const validateFileType = (file, accept) => {
  const acceptedTypes = accept.split(',')
  if (!acceptedTypes.includes('.' + file.name.toLowerCase().split('.').pop())) {
    return false
  }
  return true
}

const MIME_TYPE_ALLOWED = [
  {
    extension: 'doc',
    mime_type: 'application/msword',
  },
  {
    extension: 'docx',
    mime_type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  },
  {
    extension: 'pdf',
    mime_type: 'application/pdf',
  },
  {
    extension: 'gz',
    mime_type: 'application/gzip',
  },
  {
    extension: 'gz',
    mime_type: 'application/x-gzip-compressed',
  },
  {
    extension: 'rar',
    mime_type: 'application/vnd.rar',
  },
  {
    extension: 'tar',
    mime_type: 'application/tar',
  },
  {
    extension: 'tar',
    mime_type: 'application/x-tar',
  },
  {
    extension: '7z',
    mime_type: 'application/7z',
  },
  {
    extension: '7z',
    mime_type: 'application/x-7z-compressed',
  },
  {
    extension: 'zip',
    mime_type: 'application/zip',
  },
  {
    extension: 'zip',
    mime_type: 'application/x-zip-compressed',
  },
]

const validateMimeTypex = (data) => {
  const arrayMimeType = MIME_TYPE_ALLOWED
  return arrayMimeType.find((element) => {
    return element.extension === data.name.split('.').pop() && element.mime_type === data.type
      ? true
      : false
  })
}

export { validateMIMEType, validateFileType, validateMimeTypex }
