import { useCallback } from 'react'
// import { useDispatch } from 'react-redux'
// import { HideLoading } from '../../redux/loading/actionCreator'
import ApiService from '../../services/ApiService'
import { setItem } from '../../utility/Session'
import { encryptCrypto } from '../../utility/Encryption'

const InteractorAuthorization = () => {
  // const dispatch = useDispatch()
  const token = useCallback(() => {
    const sign = encryptCrypto(
      JSON.stringify({
        public_key: process.env.REACT_APP_PUBLIC_KEY,
        private_key: process.env.REACT_APP_PRIVATE_KEY,
      }).toString(),
    )
    const payload = {
      sign,
    }
    ApiService.jsonRequest('/application/initialization', payload, (response) => {
      setItem({
        key: '_wdti',
        value: response.access_token,
        expires: new Date(response.exp * 1000).getTime(),
      })
      // dispatch(HideLoading())
      window.location.reload(false)
    })
  }, [])

  return { token }
}

export default InteractorAuthorization
