import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import './components/styles/main.css'
import './utility/i18n'
import * as serviceWorker from './serviceWorker'
import { HelmetProvider } from 'react-helmet-async'
import { Provider } from 'react-redux'
import store from './redux/store'
import { CssBaseline } from '@mui/material'

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <HelmetProvider>
        <CssBaseline />
        <App />
      </HelmetProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
)
serviceWorker.register()
