import { Container } from '@mui/system'
import styled from 'styled-components'
import { ThemeOptions } from '../../config/ThemeOptions'

const { fonts, breakpoints } = ThemeOptions

export const UnitDetailContainer = styled(Container)`
  .unit {
    @media screen and (max-width: ${`${breakpoints.values.md}px`}) {
      .unit-item:last-child {
        order: -1;
      }
    }
    @media screen and (min-width: ${`${breakpoints.values.md}px`}) {
      &:nth-child(odd) {
        .unit-item:last-child {
          order: -1;
        }
      }
    }
    &-headlines {
      margin-top: 0;
    }
    &-logo {
      max-height: 72px;
      object-position: left;
      margin-bottom: 10px;
    }
    &-link {
      font-size: ${fonts.md};
      font-weight: 600;
      svg {
        position: absolute;
        top: 51%;
        transform: translateY(-50%);
      }
    }
    &::after {
      content: '';
      display: block;
      width: 100%;
      margin: 30px auto;
      height: 2px;
      background: linear-gradient(238deg, #3ab6ff -7.16%, #b1737a 75.28%, #ffb038 138.04%);
      align-self: center;
      @media screen and (max-width: ${`${breakpoints.values.md}px`}) {
        width: 95%;
      }
    }
  }
`
