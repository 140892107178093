import React, { Suspense } from 'react'
import { Loading } from '../components/Main'
import {
  Home,
  AboutUs,
  ProductPage,
  ContactUsPage,
  BusinessUnitPage,
  CareerPage,
  NewsPage,
} from './../containers/Main'
import i18n from './../utility/i18n'

function getTitle(key) {
  let lang = i18n.t(key)
  if (lang === undefined) {
    lang = 'Wide Technologies Indonesia'
  }
  return lang
}
const RoutePath = [
  {
    path: '/',
    title: getTitle('menu.home'),
    element: (
      <Suspense fallback={<Loading />}>
        <Home />
      </Suspense>
    ),
    auth: false,
  },
  {
    path: '/products/:product_name',
    title: getTitle('menu.product'),
    element: (
      <Suspense fallback={<Loading />}>
        <ProductPage />
      </Suspense>
    ),
    auth: false,
  },
  {
    path: '/news/:news_slug',
    title: getTitle('menu.news'),
    element: (
      <Suspense fallback={<Loading />}>
        <NewsPage />
      </Suspense>
    ),
    auth: false,
  },
  {
    path: '/about-us',
    title: getTitle('menu.about'),
    element: (
      <Suspense fallback={<Loading />}>
        <AboutUs />
      </Suspense>
    ),
    auth: false,
  },
  {
    path: '/career',
    title: getTitle('menu.career'),
    element: (
      <Suspense fallback={<Loading />}>
        <CareerPage />
      </Suspense>
    ),
    auth: false,
  },
  {
    path: '/contact-us',
    title: getTitle('menu.contact'),
    element: (
      <Suspense fallback={<Loading />}>
        <ContactUsPage />
      </Suspense>
    ),
    auth: false,
  },
  {
    path: '/business-unit',
    title: getTitle('menu.contact'),
    element: (
      <Suspense fallback={<Loading />}>
        <BusinessUnitPage />
      </Suspense>
    ),
    auth: false,
  },
]

export default RoutePath
