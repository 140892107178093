import { Box } from '@mui/system'
import styled from 'styled-components'

export const FabBox = styled(Box)`
  overflow: visible;
  position: fixed;
  bottom: 0px;
  right: 30px;
  button {
    box-shadow: none;
    filter: drop-shadow(0 0 5px #00000050);
  }
  img {
    width: 60%;
    height: 60%;
  }
`
