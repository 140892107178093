import { useCallback } from 'react'
import ApiService from '../../services/ApiService'

const InteractorUploadMediaFile = () => {
  const upload = useCallback((body, callback, loading) => {
    ApiService.formRequest('/media/file/upload', body, (response) => {
      loading(false)
      if (response.code === 7200) {
        callback(response.data[0])
      } else {
        callback('')
      }
    })
  }, [])
  return { upload }
}

export default InteractorUploadMediaFile
