import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Fragment, useState } from 'react'
import { AppBar } from '@mui/material'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { menuList } from '../../constant/menu/menuList'
import logo from '../../assets/image/wide-logo.svg'
import { NavBox, NavCol, NavItem } from './styledHeader'
import HeaderMobile from './HeaderMobile'
import { useTranslation } from 'react-i18next'
import { ThemeOptions } from '../../config/ThemeOptions'
import { scrollToSection } from '../../utility/Helpers'

export const Header = () => {
  const loc = useLocation().pathname
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { palette } = ThemeOptions
  const [scrollPosition, setScrollPosition] = useState(0)

  const handleScroll = () => {
    const position = window.pageYOffset
    setScrollPosition(position)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <AppBar
      style={
        (loc.search('products/') === 1 || loc.search('news/') === 1) && scrollPosition === 0
          ? {
              background: `${palette.gradient_blue.inverted}`,
              height: 90,
            }
          : scrollPosition === 0
          ? {
              background: 'transparent',
              boxShadow: 'none',
              color: '#e0e6eb',
              height: 90,
            }
          : null
      }
    >
      <NavBox
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        sx={
          scrollPosition !== 0
            ? {
                '&::before': {
                  opacity: 0,
                },
              }
            : null
        }
      >
        <NavCol key="logo">
          <Link className="nav-logo" to={'/'}>
            <img
              loading="lazy"
              height="80"
              width="80"
              src={logo}
              alt="WideTechnologiesIndonesiaLogo"
              style={
                scrollPosition === 0
                  ? {
                      width: 80,
                      height: 80,
                    }
                  : null
              }
            />
          </Link>
        </NavCol>

        <NavCol key="navmenu" sx={{ display: { xs: 'none !important', md: 'flex !important' } }}>
          {menuList.map((items, i) => {
            let { name, path } = items
            const hrefProps =
              path.search('#') === -1
                ? { onClick: () => navigate(path) }
                : {
                    onClick: () => {
                      if (loc === '/') {
                        scrollToSection(path)
                      } else {
                        navigate('/')
                        setTimeout(() => {
                          scrollToSection(path)
                        }, 1000)
                      }
                    },
                  }
            return (
              <Fragment key={`item-${i}`}>
                <NavItem className={scrollPosition === 0 ? 'top' : null} {...hrefProps}>
                  {t(`menu.${name}`)}
                </NavItem>
              </Fragment>
            )
          })}
        </NavCol>
        <HeaderMobile menuList={menuList} scrollPosition={scrollPosition} loc={loc} />
      </NavBox>
    </AppBar>
  )
}

Header.propTypes = {
  layoutChat: PropTypes.bool,
}

export default Header
