import { Box } from '@mui/system'
import styled from 'styled-components'

export const DescriptionBox = styled(Box)`
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  p {
    -webkit-line-clamp: 4;
  }
`
