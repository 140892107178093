import { decryptCrypto, encryptCrypto } from './Encryption'

export const setItem = (data) => {
  const row = {
    value: data.value,
    expires: data.expires ?? null,
  }
  return localStorage.setItem(data.key, encryptCrypto(JSON.stringify(row)))
}

export const getItem = (key) => {
  const data = typeof window !== 'undefined' ? localStorage.getItem(key) : null
  let value = null
  if (data) {
    const storage = decryptCrypto(data)
    if (storage.expires) {
      if (storage.expires > new Date().getTime()) {
        value = storage.value
      } else {
        localStorage.removeItem(key) // Has Been Expired
      }
    } else {
      value = storage.value
    }
  }
  return value
}

export const removeItem = (key) => {
  localStorage.removeItem(key)
}
