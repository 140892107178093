import React, { useEffect } from 'react'
import { Routes, Route, BrowserRouter } from 'react-router-dom'
import AppLayout from './layouts/AppLayout'
import ReactGA from 'react-ga'
import { useSelector, useDispatch } from 'react-redux'
import ScrollToTop from './routes/ScrollToTop'
import { ThemeProvider } from '@mui/material'
import { ThemeOptions } from './config/ThemeOptions'
import { Loading } from './components/Main'
import {
  InteractorAuthorization,
  InteractorBusinessUnitSearch,
  InteractorProductSearch,
  InteractorServiceSearch,
} from './interactors/Main'
import { HideLoading, ShowLoading } from './redux/loading/actionCreator'
import { getItem } from './utility/Session'
import { listPayload } from './constant/default-data/payload'
import { SetBusinessUnit, SetHomeProduct, SetHomeService } from './redux/content/actionCreator'
import { createPathInArray } from './utility/Helpers'

export default function App() {
  const { loading } = useSelector((state) => state.loading)
  const dispatch = useDispatch()
  const { token } = InteractorAuthorization()
  const { serviceSearch } = InteractorServiceSearch()
  const { productSearch } = InteractorProductSearch()
  const { unitSearch } = InteractorBusinessUnitSearch()

  const setGA = () => {
    ReactGA.initialize('UA-xxxxxx-xx')
    ReactGA.pageview(window.location.pathname + window.location.search)
  }

  useEffect(() => {
    if (!getItem('_wdti')) {
      dispatch(ShowLoading())
      token()
    } else {
      serviceSearch({ ...listPayload, sort: 'created_date asc' }, (response) => {
        dispatch(SetHomeService(response))
      })
      productSearch({ ...listPayload, sort: 'sort_number asc' }, (response) => {
        dispatch(SetHomeProduct(createPathInArray('products', response)))
      })
      unitSearch({ ...listPayload, sort: 'sort_number asc' }, (response) => {
        dispatch(SetBusinessUnit(response))
      })
      dispatch(HideLoading())
    }

    setGA()
  }, [dispatch, productSearch, serviceSearch, token, unitSearch])
  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <BrowserRouter>
          <ThemeProvider theme={ThemeOptions}>
            <ScrollToTop>
              <Routes>
                <Route path="*" element={<AppLayout />} />
              </Routes>
            </ScrollToTop>
          </ThemeProvider>
        </BrowserRouter>
      )}
    </>
  )
}
