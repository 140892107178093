import axios from 'axios'
import { showAlert } from '../utility/SweetAlert'
import { getItem } from '../utility/Session'
import { getTranslate } from '../utility/MapperTranslate'

const ApiService = {
  jsonRequest: async (path, payload, callback) => {
    const url = process.env.REACT_APP_API_BASE + path
    const bearer = getItem('_wdti')
    const headers = {
      'Content-Type': 'application/json',
    }
    if (bearer) {
      headers.Authorization = `Bearer ${bearer}`
    }
    const requestOptions = {
      method: 'POST',
      url,
      headers,
      data: payload,
    }
    axios(requestOptions)
      .then((response) => {
        callback(response.data)
      })
      .catch((error) => {
        const { status, statusText } = error.response
        if (statusText.toUpperCase() === 'UNAUTHORIZED' && status === 403) {
          showAlert({
            icon: 'warning',
            message: getTranslate('message.token_expired'),
            timer: 3000,
            reload: true,
          })
        }
        callback(error.response)
      })
  },
  formRequest: async (path, payload, callback) => {
    const url = process.env.REACT_APP_API_BASE + path
    const bearer = getItem('_wdti')
    const headers = {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${bearer}`,
    }
    let formdata = new FormData()
    Object.keys(payload).map((key) => formdata.append(key, payload[key]))
    const requestOptions = {
      method: 'POST',
      url,
      headers,
      data: formdata,
    }
    axios(requestOptions)
      .then((response) => {
        callback(response.data)
      })
      .catch((error) => {
        const { status, statusText } = error.response
        if (statusText.toUpperCase() === 'UNAUTHORIZED' && status === 403) {
          showAlert({
            icon: 'warning',
            message: getTranslate('message.token_expired'),
            timer: 3000,
            reload: true,
          })
        }
        callback(error.response)
      })
  },
}

export default ApiService
