import { Button, Card, CardContent, CardHeader, Grid, TextField } from '@mui/material'
import React, { createRef, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { GradientTitle, Loading } from '../Main'
import { ContactUsFormBox } from './styledContactUsForm'
import { PropTypes } from 'prop-types'
import ReCAPTCHA from 'react-google-recaptcha'
import { useFormik } from 'formik'
import { FormSchema } from '../../utility/FormValidationSchema'
import { InteractorContactUsCreate } from '../../interactors/Main'

const ContactUsForm = ({ title, subtitle, size }) => {
  const { t } = useTranslation()
  const { createMessage } = InteractorContactUsCreate()
  const [loading, setLoading] = useState(false)
  const [send, setSend] = useState(false)
  const recaptchaRef = createRef()
  let sizeForm = size ? size : 'small'
  const recaptchaChange = (value) => {
    if (value) {
      formik.setFieldValue('captha', value)
    }
  }
  useEffect(() => {
    if (Object.keys(formik.errors).length) {
      let el = document.getElementsByClassName('Mui-error')[0]
      el?.parentElement?.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
  })

  const formik = useFormik({
    initialValues: {
      full_name: '',
      company_name: '',
      email_address: '',
      phone_number: '',
      message: '',
      captha: '',
    },
    validationSchema: FormSchema,
    onSubmit: (values) => {
      setLoading(true)
      createMessage(values, setLoading)
      setSend(true)
    },
  })
  const inputProps = {
    required: true,
    size: sizeForm,
    color: 'primary',
  }
  return (
    <ContactUsFormBox component="form" autoComplete="off" sx={{ boxShadow: 3 }}>
      <Card className="card">
        {send ? null : (
          <>
            {title ? <GradientTitle title={title} /> : null}
            <CardHeader className="card-header" title={subtitle} />
          </>
        )}
        <CardContent>
          {loading ? (
            <Loading />
          ) : send ? (
            <GradientTitle
              title={t('title.thank_you_contact_us')}
              subtitle={t('subtitle.thank_you_contact_us')}
            />
          ) : (
            <>
              <Grid container spacing={2} justifyContent="center">
                <Grid item xs={12} md={5}>
                  <TextField
                    {...inputProps}
                    label={t('placeholder.fullname')}
                    id="full_name"
                    name="full_name"
                    onChange={formik.handleChange}
                    error={formik.touched.full_name && formik.errors.full_name ? true : false}
                    helperText={
                      formik.touched.full_name && formik.errors.full_name
                        ? formik.errors.full_name
                        : null
                    }
                  />
                </Grid>
                <Grid item xs={12} md={5}>
                  <TextField
                    {...inputProps}
                    label={t('placeholder.company')}
                    id="company_name"
                    name="company_name"
                    onChange={formik.handleChange}
                    error={formik.touched.company_name && formik.errors.company_name ? true : false}
                    helperText={
                      formik.touched.company_name && formik.errors.company_name
                        ? formik.errors.company_name
                        : null
                    }
                  />
                </Grid>
                <Grid item xs={12} md={5}>
                  <TextField
                    {...inputProps}
                    label={t('placeholder.email')}
                    id="email_address"
                    name="email_address"
                    onChange={formik.handleChange}
                    error={
                      formik.touched.email_address && formik.errors.email_address ? true : false
                    }
                    helperText={
                      formik.touched.email_address && formik.errors.email_address
                        ? formik.errors.email_address
                        : null
                    }
                  />
                </Grid>
                <Grid item xs={12} md={5}>
                  <TextField
                    {...inputProps}
                    label={t('placeholder.phone')}
                    id="phone_number"
                    name="phone_number"
                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                    onChange={formik.handleChange}
                    error={formik.touched.phone_number && formik.errors.phone_number ? true : false}
                    helperText={
                      formik.touched.phone_number && formik.errors.phone_number
                        ? formik.errors.phone_number
                        : null
                    }
                  />
                </Grid>
                <Grid item xs={12} md={10}>
                  <TextField
                    {...inputProps}
                    label={t('placeholder.message')}
                    id="message"
                    name="message"
                    onChange={formik.handleChange}
                    rows={3}
                    multiline
                    error={formik.touched.message && formik.errors.message ? true : false}
                    helperText={
                      formik.touched.message && formik.errors.message ? formik.errors.message : null
                    }
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={10}
                  sx={{ paddingLeft: { xs: '8px !important', md: '16px' } }}
                >
                  <ReCAPTCHA
                    ref={recaptchaRef}
                    sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                    onChange={recaptchaChange}
                    className="ReCaptha"
                  />
                  {formik.touched.captha && formik.errors.captha ? (
                    <span
                      id="my-helper-text"
                      style={{ color: 'red', display: 'block', textAlign: 'center' }}
                    >
                      {t('validate.recaptcha')}
                    </span>
                  ) : null}
                </Grid>
              </Grid>
              <Button
                variant="contained"
                size={sizeForm === 'large' ? 'large' : 'medium'}
                sx={{ marginTop: 2 }}
                onClick={formik.handleSubmit}
              >
                {t('button.submit')}
              </Button>
            </>
          )}
        </CardContent>
      </Card>
    </ContactUsFormBox>
  )
}
ContactUsForm.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  size: PropTypes.oneOf(['large', 'medium', 'small']),
}
export default ContactUsForm
