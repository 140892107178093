import { Button } from '@mui/material'
import { Box } from '@mui/system'
import styled from 'styled-components'
import waveTop from '../../assets/ornament/wave-top.svg'
import { ThemeOptions } from '../../config/ThemeOptions'

const { palette, breakpoints } = ThemeOptions

export const NavBox = styled(Box)`
  height: 100%;
  margin: 0 5%;
  padding: 0 10px;
  &:before {
    content: '';
    z-index: -1;
    display: block;
    position: absolute;
    width: 30vw;
    height: 140px;
    top: -1.9vh;
    left: -3vh;
    transform: rotate(5deg);
    background: url(${waveTop}) no-repeat;
    background-size: contain;
    @media screen and (min-width: ${`${breakpoints.values.lg}px`}) {
      left: -1.5vh;
    }
    @media screen and (max-width: ${`${breakpoints.values.md}px`}) {
      width: 55vw;
      left: -5vh;
    }
  }
`

export const NavCol = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  img {
    width: 60px;
    height: 60px;
    margin: 0 !important;
  }
  .top {
    color: #fff;
    &:hover {
      color: ${palette.secondary.main};
    }
    &::after {
      background-color: ${palette.secondary.main};
    }
  }
`

export const NavItem = styled(Button)`
  background-color: transparent;
  height: 100%;
  color: ${palette.text.primary};
  padding-bottom: 0;
  padding-top: 0;
  margin-left: 20px;
  font-weight: 600;
  font-size: 1rem;
  position: relative;
  &::after {
    background: none repeat scroll 0 0 transparent;
    bottom: 10px;
    content: '';
    display: block;
    height: 2px;
    left: 50%;
    position: absolute;
    background: #2c77a3;
    transition: width 0.3s ease 0s, left 0.3s ease 0s;
    width: 0;
  }
  &:hover {
    background-color: transparent !important;
    color: ${palette.secondary.main};
    &::after {
      width: 100%;
      left: 0;
      background-color: ${palette.secondary.main};
    }
  }
`
