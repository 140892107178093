import React, { useEffect } from 'react'
import { Routes, Route, useLocation } from 'react-router-dom'
import RoutePath from '../routes/RoutePath'
import { setPageTitle } from '../utility/SearchEngine'

export default function AppContent() {
  let currentPath = useLocation()
  useEffect(() => {
    setPageTitle(RoutePath, currentPath)
  }, [currentPath])

  return (
    <>
      <main id="content" role="main">
        <Routes>
          {RoutePath.map((route, key) => {
            return <Route key={key} path={route.path} title={route.title} element={route.element} />
          })}
        </Routes>
      </main>
    </>
  )
}
